<template>
  <a-form
    @submit="submit"
    :form="contactForm"
    layout="vertical"
    style="padding: 5%"
  >
   
    <div class="splitter">
      <a-form-item v-bind="formItemLayout">
        <a-input
          placeholder="Nom & Prénom"
          v-decorator="[
            'full_name',
            {
              rules: [
                {
                  required: true,
                  message: 'Nom & Prénom est obligatoire!',
                },
              ],
            },
          ]"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item v-bind="formItemLayout">
        <a-input
          placeholder="Email"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Email est obligatoire!',
                },
              ],
            },
          ]"
        >
          <a-icon
            slot="prefix"
            type="mail"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item v-bind="formItemLayout">
        <a-input
          placeholder="Sujet"
          v-decorator="[
            'subject',
            {
              rules: [
                {
                  required: true,
                  message: 'Sujet est obligatoire!',
                },
              ],
            },
          ]"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item v-bind="formItemLayout">
        <a-textarea
          placeholder="Message"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          v-decorator="[
            'message',
            {
              rules: [
                {
                  required: true,
                  message: 'Message de passe est obligatoire!',
                },
              ],
            },
          ]"
        />

        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </a-form-item>
      <a-form-item v-bind="tailFormItemLayout" class="margin">
        <a-spin :spinning="spinning">
          <a-button
            type="primary"
            class="regiter-form-button"
            htmlType="submit"
            id="login"
            >{{ spinning ? "Envoie en cours..." : "Envoyer" }}
          </a-button>
        </a-spin>
      </a-form-item>
    </div>
  </a-form>
</template>
<script>
import auth from "@/services/auth";

export default {
  beforeCreate() {
    this.contactForm = this.$form.createForm(this);
  },
  props: {
    spinning: { type: Boolean, default: () => false },
  },
  methods: {
    startContactFormProcessing() {
      this.spinning = true;
    },
    stopContactFormProcessing() {
      this.spinning = false;
    },
    submit(event) {
      event.preventDefault();
      this.contactForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.startContactFormProcessing();
          auth
            .axios()
            .post("/contact", { ...values })
            .then(() => this.$emit("form-submitted"))
            .catch(() => this.displayErrors(true))
            .finally(() => this.stopContactFormProcessing());
        }
      });
    },
  },
};
</script>